import React from 'react';
import './style.scss';

function CardHeader({ title, subtitle, icon, wrapperClass = '' }) {
  return (
    <div className={`card-header ${wrapperClass}`}>
      <div className="flex items-center gap-3">
        {icon && <img src={icon} alt="" className="w-8 h-8 md:w-8 md:h-8" />}
        <div className="header-title">{title}</div>
      </div>
      {subtitle && (
        <div className="text-sm mt-2 text-up-clr-dark-grey font-semibold">
          {subtitle}
        </div>
      )}
    </div>
  );
}

export default CardHeader;
