import React from 'react';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { redirectToPlatform } from '../../api/auth';
import CardHeader from '../../components/CardHeader';
import ArrowRightIcon from '../../assets/icons/ArrowRightIcon';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import PlaceholderBiz from '../../assets/images/placeholder-biz.svg';
import { redirectUserToPlatform } from '../../helpers/bizDataHelpers';
import PrimeAccessRequired from './PrimeAccessRequired';
import { checkErrorTypeString } from '../../helpers/utils';
import UpToast from '../../components/Common/UpToast';

function SelectBusiness() {
  const navigate = useNavigate();
  const appLogin = LocalStorageHelper.getAppLogin();
  const allBizData = LocalStorageHelper.getBizData();
  const redirectHost = LocalStorageHelper.getRedirectHost();

  const { isLoading, mutate: callRedirectPlatform } = useMutation({
    mutationFn: ({ appType, params }) => redirectToPlatform(appType, params),
    onSuccess: redirectUserToPlatform,
    onError: error => {
      const errorMessage = checkErrorTypeString(error?.response?.data?.message);
      UpToast.error(errorMessage);
    },
  });

  function redirectToBizPlatform(bizData) {
    if (bizData?.is_login_restricted) {
      navigate('/login-restricted', { state: { bizData, reason_code: bizData?.login_restriction_reason || 'billing' } });
      return;
    }

    const availablePlatforms = [];

    (bizData?.appsbiz || []).forEach(biz => {
      availablePlatforms.push(biz?.app_name);
    });

    if (availablePlatforms?.length === 1) {
      callRedirectPlatform({
        appType: availablePlatforms?.[0],
        params: {
          biz_id: bizData?.biz_id,
          app_name: availablePlatforms?.[0],
          app_biz_id: bizData?.appsbiz?.[0]?.app_biz_id,
          redirect_host: redirectHost,
        },
      });
      return;
    }

    navigate(`/business/${bizData?.biz_id}`);
  }

  if (allBizData?.length === 0 && appLogin === 'prime') {
    return <PrimeAccessRequired />;
  }

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <div>
          <CardHeader title="Select a business" wrapperClass="pb-6" />
          {!!allBizData?.length && (
            <p className="text-sm text-up-clr-light-grey py-2">
              Your businesses ({allBizData?.length})
            </p>
          )}
          {allBizData?.map((bizData, idx) => {
            return (
              <div key={bizData?.biz_id}>
                <BusinessCard
                  bizData={bizData}
                  redirectToBizPlatform={redirectToBizPlatform}
                />
                {idx < allBizData?.length - 1 && (
                  <div className="border-t border-up-clr-neutral-grey md:-mx-8" />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function BusinessCard({ bizData, redirectToBizPlatform }) {
  const biz = bizData?.appsbiz?.[0] || {};
  const allRoles = bizData?.appsbiz?.reduce((acc, appBiz) => {
    if (appBiz?.roles_name?.length > 0) {
      acc.push(...appBiz.roles_name);
    }
    return acc;
  }, []);

  return (
    <div
      onClick={() => redirectToBizPlatform(bizData)}
      className="my-6 flex items-center cursor-pointer transition-colors duration-500 text-up-clr-neutral-grey hover:text-up-clr-tinted-grey">
      <div className="w-12 h-12 flex-shrink-0 self-start">
        <img
          src={bizData?.biz_logo || biz?.app_biz_logo || PlaceholderBiz}
          alt={bizData?.biz_name || biz?.app_biz_name}
          className="w-full h-full object-contain"
        />
      </div>
      <div style={{ maxWidth: 300 }} className="ml-4 mr-auto">
        <p className="text-lg font-semibold text-up-clr-black leading-6">
          {bizData?.biz_name || biz?.app_biz_name}
        </p>
        <p className="text-sm text-up-clr-dark-grey">
          {(allRoles || []).join(', ')}
        </p>
      </div>
      <div className="flex items-center gap-[8px]">
        {bizData?.is_login_restricted && (
          <span className="w-[88px] h-[26px] px-[8px] py-[2px] text-xs font-semibold bg-[#FFE5E5] text-[#B02B2B] rounded-[2px] flex items-center justify-center">
            Deactivated
          </span>
        )}
        <div className="select-none">
          <ArrowRightIcon />
        </div>
      </div>
    </div>
  );
}

export default SelectBusiness;
