import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import { verifyUserToken } from '../../api/auth';
import LoadingCard from '../../components/LoadingCard';
import LocalStorageHelper from '../../helpers/localStorageHelpers';
import { getRedirectRoute } from '../../helpers/bizDataHelpers';
import AnalyticsService from "../../services/analytics";
import Vent from '../../vent';

const EXCLUDE_ROUTES = [
  '/logout',
  '/invite/accept',
  '/restrict-login',
  '/login-restricted',
  '/forgot-password/verify',
  '/onboarding/biz-verify',
];

function Root() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const { isLoading, mutate: callVerifyUserToken } = useMutation({
    onError: () => onLogoutUser(true),
    mutationFn: verifyUserToken,
    onSuccess: onVerifyUserSuccess,
  });

  useEffect(() => {
    if (
      location?.pathname?.includes('/s/') ||
      EXCLUDE_ROUTES.includes(location?.pathname)
    ) {
      setIsAuthenticated(true);
    } else if (LocalStorageHelper.getAccessToken()) {
      callVerifyUserToken();
    } else {
      onLogoutUser(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onVerifyUserSuccess(resp) {
    setIsAuthenticated(true);

    const redirect = getRedirectRoute(resp);

    if (redirect) {
      navigate(redirect);
      //Vent.onUserIdentified();
    }
  }

  function onLogoutUser(trackLogout = false) {
    if (trackLogout) {
      AnalyticsService.onUleLogout();
    }
    LocalStorageHelper.clearUserDataOnLogout();
    setIsAuthenticated(false);
    navigate('/login/email-mobile');
    Vent.onUserUnidentified();
  }

  return (
    <div className="bg-up-clr-lightest-grey w-full min-h-screen flex flex-col">
      <TopBar />
      <div className="flex items-center justify-center w-full flex-grow pt-8 pb-10 px-4">
        {isLoading ? (
          <LoadingCard text="Loading your businesses..." />
        ) : isAuthenticated ? (
          <Outlet />
        ) : null}
      </div>
    </div>
  );
}

export default Root;
