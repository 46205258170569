import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import BillingLoginRestriction from './BillingLoginRestriction';

function LoginRestriction() {
  const location = useLocation();
  const { bizData, reason_code: reasonCode } = location.state || {};

  // If no business data is provided, redirect to business selection
  if (!bizData) {
    return <Navigate to="/business" replace />;
  }

  // Render appropriate component based on reason code
  switch (reasonCode) {
    case 'billing':
      return <BillingLoginRestriction />;
    default:
      console.error('Unknown login restriction reason code:', reasonCode);
      // For unknown reason codes, redirect to business selection
      return <Navigate to="/business" replace />;
  }
}

export default LoginRestriction;
