import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CardHeader from '../../components/CardHeader';
import UpButton from '../../components/Common/UpButton';
import UpLoadingBar from '../../components/Common/UpLoadingBar';
import UpLoadingCover from '../../components/Common/UpLoadingCover';
import RedDollarIcon from '../../assets/images/red-dollar.svg';

function BillingLoginRestriction() {
  const location = useLocation();
  const navigate = useNavigate();
  const bizData = location.state?.bizData;
  const [isLoading, setIsLoading] = React.useState(false);

  const handleContactSupport = () => {
    try {
      setIsLoading(true);
      const bizName = bizData?.biz_name || bizData?.appsbiz?.[0]?.app_biz_name || 'Business';
      const bizId = bizData?.biz_id || 'Not Available';
      const currentDate = new Date().toLocaleDateString();

      // Format business details for better readability
      const formattedBizName = bizName.trim();
      const formattedBizId = bizId.toString().trim();
      
      const subject = `[URGENT] Account Reactivation Request - ${formattedBizName}`;
      const body = `Dear Support Team,

I am writing to request reactivation of my business account that was deactivated due to non-payment.

Account Details:
- Business Name: ${formattedBizName}
- Business ID: ${formattedBizId}
- Date of Request: ${currentDate}
- Issue: Account Deactivation (Non-payment)

I understand that:
1. The reactivation process may take 4-5 business days
2. A reactivation fee may be applicable
3. All integrations with connected platforms are currently disabled

Please guide me through the reactivation process and provide information about any pending payments or requirements.

Thank you for your assistance.

Best regards`;

      // Construct and validate mailto link
      const mailtoLink = `mailto:support@urbanpiper.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      if (mailtoLink.length > 2048) {
        throw new Error('Email content exceeds maximum length');
      }

      // Open email client
      window.location.href = mailtoLink;

      // Reset loading state after a short delay
      setTimeout(() => setIsLoading(false), 1000);
    } catch (error) {
      console.error('Error opening email client:', error);
      setIsLoading(false);
    }
  };

  return (
    <div>
      <UpLoadingBar loading={isLoading} />
      <div className="light-view-wrapper relative">
        <UpLoadingCover loading={isLoading} />
        <div>
          <CardHeader title="Account Deactivated!" icon={RedDollarIcon} />
          <div className="text-sm text-up-clr-dark-grey mt-5">
            Your account has been deactivated due to <strong>non-payment</strong>. We have already completed disintegration with connected platforms.
          </div>
          <div className="mt-4">
            <div className="text-sm text-up-clr-dark-grey font-semibold">
              To restore access to your services, please contact support as soon as possible.
            </div>
            <div className="text-sm text-up-clr-dark-grey mt-4">
              Reactivation may take 4-5 business days and could incur a reactivation fee.
            </div>
          </div>
          <div className="mt-8 space-y-4">
            <UpButton onClick={handleContactSupport} loading={isLoading}>
              {isLoading ? 'Opening Email Client...' : 'Contact Support'}
            </UpButton>
            <div className='text-center'>
              <button 
                onClick={() => navigate('/business')} 
                className="text-up-clr-primary underline text-sm font-medium"
              >
                Back to Business
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BillingLoginRestriction;
